import { FC, useEffect, useMemo, useState } from 'react';
import { Modal } from 'components/modal/modal';
import { UserBrandDashboard } from 'helpers/userPermissions';
import styled from 'styled-components';
import { IconButton, Button, Typography, Checkbox } from '@mui/material';
import { green, grey } from '@mui/material/colors';
import MUIDataTable, { MUIDataTableOptions } from 'mui-datatables';
import { PurchaseSeatModal, PurchaseSeatMode } from './PurchaseSeatModal';
import { SaveDashboardPermissionsModal } from './SaveDashboardPermissionsModal';
import _ from 'lodash';
import {
  useUserManagementAssignBrandDashboardSeatPublicUserMutation,
  useUserManagementPurchaseBrandDashboardSeatPublicUserMutation,
  useUserManagementUnassignBrandDashboardSeatPublicUserMutation,
} from 'graphql/types';
import { BillingCurrentPlanSeatsModal } from 'components/account/billing/modals/BillingCurrentPlanSeatsModal';
import { useMuiNotifications } from 'context/muiNotificationContext';
import { CircularSpinner } from 'components/circularSpinner/circularSpinner';

type DashboardPermissionsModalProps = {
  open: boolean;
  email: string;
  userId: string;
  brandId: string;
  brandName: string;
  dashboards: UserBrandDashboard[];
  onClose: (reload: boolean) => void;
  onRefreshRequested: () => void;
};

export const DashboardPermissionsModal: FC<DashboardPermissionsModalProps> = ({
  open,
  email,
  userId,
  brandId,
  brandName,
  dashboards,
  onClose,
  onRefreshRequested,
}) => {
  const { createSuccessNotification, createFailureNotification } = useMuiNotifications();
  const [assignBrandDashboardSeatMutation, { loading: assigning }] =
    useUserManagementAssignBrandDashboardSeatPublicUserMutation();
  const [unassignBrandDashboardSeatMutation, { loading: unassigning }] =
    useUserManagementUnassignBrandDashboardSeatPublicUserMutation();
  const [purchaseBrandDashboardSeatMutation, { loading: purchasing }] =
    useUserManagementPurchaseBrandDashboardSeatPublicUserMutation();

  const [data, setData] = useState<UserBrandDashboard[]>([]);
  const [changedDashboards, setChangedDashboards] = useState<UserBrandDashboard[]>([]);
  const [openPurchaseSeatModal, setOpenPurchaseSeatModal] = useState<boolean>(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState<boolean>(false);
  const [changed, setChanged] = useState(false);
  const [selectedDashboard, setSelectedDashboard] = useState<UserBrandDashboard>();
  const [selectedPurchaseMode, setSelectedPurchaseMode] = useState<PurchaseSeatMode>('ADD_NEW_SEAT');
  const [openSeatsModal, setOpenSeatsModal] = useState(false);
  const [pendingSeatCount, setPendingSeatCount] = useState<number[]>([]);
  const [hasPurchasedSeats, setHasPurchasedSeats] = useState<boolean>(false);

  const windowHeight = window.innerHeight;

  const handleClickOutside = () => {
    const modifiedDashboards = data.filter((dashboard) => {
      const item = dashboards.find((e) => e.name === dashboard.name);
      if (!item) {
        return false;
      } else {
        return item.seats.length !== dashboard.seats.length;
      }
    });
    setHasPurchasedSeats(false);
    onClose(modifiedDashboards.length > 0 || hasPurchasedSeats);
  };

  const onClickSave = () => {
    setOpenConfirmationModal(true);
  };

  const handleSaveChanges = async () => {
    setOpenConfirmationModal(false);

    let success = true;
    for (const dashboard of changedDashboards) {
      try {
        if (dashboard.isEnabled) {
          const result = await assignBrandDashboardSeatMutation({
            variables: {
              userId: userId,
              brandId: brandId,
              dashboardName: dashboard.name,
            },
          });
          if (result.data?.assignBrandDashboardSeatPublicUser !== true) {
            success = false;
          } else {
            createSuccessNotification(`Associated seat.`);
          }
        } else {
          const result = await unassignBrandDashboardSeatMutation({
            variables: {
              userId: userId,
              brandId: brandId,
              dashboardName: dashboard.name,
            },
          });
          if (result.data?.unassignBrandDashboardSeatPublicUser !== true) {
            success = false;
          } else {
            createSuccessNotification('Removed seat association');
          }
        }
      } catch (e) {
        createFailureNotification(`Failed to perform operation. Please contact support. ${e}`);
      }
    }

    if (success) {
      setChangedDashboards([]);
      setHasPurchasedSeats(false);
      onClose(true);
    }
  };

  const handlePurchaseSeat = async (index?: number) => {
    const dashboard = index !== undefined ? data[index] : selectedDashboard;
    if (!dashboard) {
      return;
    }

    try {
      const result = await purchaseBrandDashboardSeatMutation({
        variables: {
          brandId: brandId,
          dashboardName: dashboard.name,
          evalOnly: false,
        },
      });

      if (result && result.data?.purchaseBrandDashboardSeatPublicUser) {
        const index = data.findIndex((e) => e.name === dashboard.name);
        if (index === -1) {
          return;
        }
        const newItem = data[index];
        const newSeats = [...newItem.seats, { id: '', isSoftCancelled: false, isFreeSeat: false }];
        newItem.seats = newSeats;
        const newData = data.map((item, itemIndex) => (itemIndex === index ? { ...item, ...newItem } : item));
        setData(newData);
        createSuccessNotification(`Seat purchased.`);
        setHasPurchasedSeats(true);
      } else if (result?.errors?.length) {
        createFailureNotification(`Failed to create new seat. Please contact support. ${result.errors[0].message}`);
      }
    } catch (e) {
      createFailureNotification(`Failed to create new seat. Please contact support. ${e}`);
    }
  };

  useEffect(() => {
    const reference = _.cloneDeep(dashboards);
    setData(reference);
  }, [open, dashboards]);

  useEffect(() => {
    const modifiedDashboards = data.filter((dashboard) => {
      const item = dashboards.find((e) => e.name === dashboard.name);
      if (!item) {
        return false;
      } else {
        return item.isEnabled !== dashboard.isEnabled;
      }
    });
    setChangedDashboards(modifiedDashboards);
    setChanged(modifiedDashboards.length > 0);
  }, [data, dashboards]);

  const Columns = useMemo(() => {
    const handleSelectEnable = (index: number) => {
      const dashboard = data[index];
      const seats = dashboard.seats;
      const available = seats.filter((e) => !e.assignedToEmail);
      if (available.length === 0 && !dashboard.isEnabled) {
        setSelectedDashboard(dashboard);
        setSelectedPurchaseMode('NO_AVAILABLE_SEATS');
        setOpenPurchaseSeatModal(true);
      } else {
        const newItem = data[index];
        newItem.isEnabled = !newItem.isEnabled;
        const newData = data.map((item, itemIndex) => (itemIndex === index ? { ...item, ...newItem } : item));
        setData(newData);
        setSelectedPurchaseMode('ADD_NEW_SEAT');
      }
    };

    const handleViewSeats = (index: number) => {
      const dashboard = data[index];
      setSelectedDashboard(dashboard);
      setOpenSeatsModal(true);
    };

    return [
      {
        label: 'Dashboard',
        name: 'name',
      },
      {
        label: `Seat assigned?`,
        name: 'isEnabled',
        options: {
          filter: false,
          sort: false,
          empty: true,
          customBodyRenderLite: (dataIndex: number) => {
            const checked = data[dataIndex].isEnabled;
            const name = data[dataIndex].name;

            const originalItem = dashboards.find((e) => e.name === name);
            if (originalItem?.isEnabled !== checked) {
              const newPendingSeatCount = [...pendingSeatCount];
              newPendingSeatCount[dataIndex] = checked ? -1 : 1; // checked means one seat less to be available
              if (newPendingSeatCount[dataIndex] !== pendingSeatCount[dataIndex]) {
                setPendingSeatCount(newPendingSeatCount);
              }
            } else if (pendingSeatCount[dataIndex]) {
              const newPendingSeatCount = [...pendingSeatCount];
              newPendingSeatCount[dataIndex] = 0; // checked means one seat less to be available
              setPendingSeatCount(newPendingSeatCount);
            }

            return (
              <StyledIconContainer>
                <IconButton
                  sx={{ color: checked ? green[500] : grey[500] }}
                  title={
                    !checked
                      ? `Check to assign ${name}'s seat to ${email}`
                      : `Uncheck to unassign ${name}'s seat used by ${email}`
                  }
                  onClick={() => {
                    handleSelectEnable(dataIndex);
                  }}
                >
                  <Checkbox checked={checked} color={checked ? 'success' : 'default'} disabled={checked === false} />
                </IconButton>
              </StyledIconContainer>
            );
          },
        },
      },
      {
        label: 'Seats available',
        name: 'seats',
        options: {
          filter: false,
          sort: false,
          empty: true,
          customBodyRenderLite: (dataIndex: number) => {
            const seats = data[dataIndex].seats;
            const available = seats.filter((e) => !e.assignedToEmail);
            const deltaPending = pendingSeatCount[dataIndex] ?? 0;
            const availableSeatsCount = available.length + deltaPending;

            return <Typography>{`${availableSeatsCount} of ${seats.length}`}</Typography>;
          },
        },
      },
      {
        label: 'Actions',
        name: 'actions',
        options: {
          filter: false,
          sort: false,
          empty: true,
          customBodyRenderLite: (dataIndex: number) => {
            const name = data[dataIndex].name;

            return (
              <div>
                <StyledLinkButton onClick={() => handleViewSeats(dataIndex)} title={`Manage seats for ${name}`}>
                  Manage seats
                </StyledLinkButton>
                <br />
                <StyledLinkButton
                  onClick={() => {
                    setSelectedPurchaseMode('ADD_NEW_SEAT');
                    setSelectedDashboard(data[dataIndex]);
                    setOpenPurchaseSeatModal(true);
                  }}
                  title={`Purchase a seat for ${name}`}
                >
                  <b>Purchase seat</b>
                </StyledLinkButton>
              </div>
            );
          },
        },
      },
    ];
  }, [data, pendingSeatCount, dashboards, email]);

  const options: MUIDataTableOptions = {
    print: false,
    download: false,
    search: false,
    filter: false,
    viewColumns: false,
    selectableRowsHideCheckboxes: true,
    pagination: false,
    tableBodyMaxHeight: '390px',
    sortOrder: {
      name: 'name',
      direction: 'asc',
    },
  };

  const getChildren = () => {
    if (assigning || unassigning || purchasing) {
      return (
        <>
          {assigning ? <HeaderText>Assigning seat...</HeaderText> : null}
          {unassigning ? <HeaderText>Un-assigning seat...</HeaderText> : null}
          {purchasing ? <HeaderText>Purchasing seat...</HeaderText> : null}
          <CircularSpinner shown={true} />
        </>
      );
    } else {
      return (
        <>
          <HeaderText>
            The following dashboards can be made available to <b>{email}</b> for <b>{brandName}</b>:
          </HeaderText>
          <MUIDataTable title="" columns={Columns} data={data} options={options} />
          <StyledButtonContainer>
            <StyledCancelButton onClick={() => onClose(/* reload */ hasPurchasedSeats)}>CANCEL</StyledCancelButton>
            <StyledSubmitButton disabled={!changed} variant={'contained'} onClick={onClickSave}>
              SAVE CHANGES
            </StyledSubmitButton>
          </StyledButtonContainer>
          <PurchaseSeatModal
            open={openPurchaseSeatModal}
            brandId={brandId}
            dashboard={selectedDashboard}
            mode={selectedPurchaseMode}
            onClose={() => {
              setOpenPurchaseSeatModal(false);
            }}
            onPurchase={() => {
              setOpenPurchaseSeatModal(false);
              handlePurchaseSeat();
            }}
          />
          <SaveDashboardPermissionsModal
            open={openConfirmationModal}
            email={email}
            brand={brandName}
            dashboards={changedDashboards}
            onClose={() => {
              setOpenConfirmationModal(false);
            }}
            onSave={() => {
              handleSaveChanges();
            }}
          />
          {selectedDashboard?.name ? (
            <BillingCurrentPlanSeatsModal
              open={openSeatsModal}
              brandId={brandId}
              brandName={brandName}
              dashboardName={selectedDashboard.name}
              onClose={(needsReload) => {
                setOpenSeatsModal(false);
                needsReload && onRefreshRequested();
              }}
            />
          ) : null}
        </>
      );
    }
  };

  const modalHeight = useMemo(() => {
    const offset = 333;
    let height = offset + data.length * 100;
    if (height > windowHeight - offset) {
      height = windowHeight - offset;
    }
    return height;
  }, [data, windowHeight]);

  return (
    <Modal
      width={800}
      height={modalHeight}
      open={open}
      onClickOutside={handleClickOutside}
      title={`Dashboard Permissions`}
    >
      <StyledContainer>{getChildren()}</StyledContainer>
    </Modal>
  );
};

const StyledContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: auto;
  margin-bottom: 0;
`;

const StyledIconContainer = styled.div`
  align-items: left;
  display: flex;
  gap: 8px;
`;

const HeaderText = styled(Typography)({
  paddingBottom: '16px',
});

const StyledCancelButton = styled(Button)({
  textTransform: 'none',
  width: '160px',
  height: '40px',
  marginLeft: '16px',
});

const StyledSubmitButton = styled(Button)({
  textTransform: 'none',
  width: '160px',
  height: '40px',
  backgroundColor: '#23A4A4',
  marginLeft: '16px',
});

const StyledLinkButton = styled(Button)({
  textTransform: 'none',
  textDecoration: 'underline',
  paddingLeft: 0,
});
