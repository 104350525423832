import { FC, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import Modal from '@mui/material/Modal';
import { Button, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { UserBrandDashboardSeat, UserBrandDashboardSeatPublicUser } from 'helpers/userPermissions';
import MUIDataTable, { MUIDataTableOptions } from 'mui-datatables';
import { SeatActionConfirmationModal, SeatActionType } from './SeatActionConfirmationModal';
import {
  useStripeGetPublicPartnerDashboardQuery,
  PpPublicPartnerUserFragment,
  usePpGetPublicPartnerUsersQuery,
  useUserManagementAssignBrandDashboardSeatPublicUserMutation,
  useUserManagementUnassignBrandDashboardSeatPublicUserMutation,
  useUserManagementCancelBrandDashboardSeatPublicUserMutation,
} from 'graphql/types';
import { useMuiNotifications } from 'context/muiNotificationContext';
import { CircularSpinner } from 'components/circularSpinner/circularSpinner';

type BillingCurrentPlanSeatsModalProps = {
  open: boolean;
  brandId: string;
  brandName: string;
  dashboardName: string;
  onClose: (reload: boolean) => void;
};

export const BillingCurrentPlanSeatsModal: FC<BillingCurrentPlanSeatsModalProps> = ({
  open,
  brandId,
  brandName,
  dashboardName,
  onClose,
}) => {
  const { createSuccessNotification, createFailureNotification } = useMuiNotifications();
  const [assignBrandDashboardSeatMutation, { loading: assigning }] =
    useUserManagementAssignBrandDashboardSeatPublicUserMutation();
  const [unassignBrandDashboardSeatMutation, { loading: unassigning }] =
    useUserManagementUnassignBrandDashboardSeatPublicUserMutation();
  const [cancelSeatEvalMutation, { loading: loadingCancel }] =
    useUserManagementCancelBrandDashboardSeatPublicUserMutation();
  const [seats, setSeats] = useState<UserBrandDashboardSeat[]>([]);
  const [availablePublicPartners, setAvailablePublicPartners] = useState<PpPublicPartnerUserFragment[]>([]);
  const [selectedActionType, setSelectedActionType] = useState(SeatActionType.Assign);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState('');
  const [selectedEmail, setSelectedEmail] = useState('');
  const [selectedSeatId, setSelectedSeatId] = useState('');

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1024,
    bgcolor: '#333',
    border: '2px solid #333',
    boxShadow: 24,
    p: 4,
  };

  const {
    data: dashboardResponse,
    loading: loadingSeats,
    refetch: refetchDashboardQuery,
  } = useStripeGetPublicPartnerDashboardQuery({
    variables: {
      partnerId: brandId ?? '',
      name: dashboardName,
    },
  });

  const {
    data: publicPartnerUsersResponse,
    loading: loadingUsers,
    refetch: refetchPublicPartnerUsers,
  } = usePpGetPublicPartnerUsersQuery({
    variables: {
      partnerId: brandId ?? '',
    },
  });

  useEffect(() => {
    setSelectedActionType(SeatActionType.Assign);
    setOpenConfirmationModal(false);
    setSelectedUserId('');
    setSelectedEmail('');
    setSelectedSeatId('');
  }, []);

  useEffect(() => {
    if (!dashboardResponse?.publicPartnerDashboard) return;
    const seats: UserBrandDashboardSeat[] = [];
    dashboardResponse.publicPartnerDashboard.seats.forEach((s) => {
      let publicUsers: UserBrandDashboardSeatPublicUser[] = [];
      s.assignedToPublicUser.forEach((e) => {
        if (!!e) {
          const user = {
            id: e.id,
            email: e.email,
          };
          publicUsers = [...publicUsers, user];
        }
      });

      let status: string;
      if (s.isSoftCancelled) {
        status = 'UPCOMING CANCELLATION\n(Paid Seat)';
      } else if (s.isFreeSeat) {
        status = 'ACTIVE\n(Included Seat)';
      } else {
        status = 'ACTIVE\n(Paid Seat)';
      }

      const seat = {
        id: s.id,
        isSoftCancelled: s.isSoftCancelled,
        isFreeSeat: s.isFreeSeat,
        assignedToEmail: s.assignedToEmail,
        assignedToPublicUser: publicUsers,
        seat: 0,
        status,
      };
      seats.push(seat);
    });
    setSeats(
      seats
        .sort((a, b) => (a.assignedToEmail ?? 'zzzzzz').localeCompare(b.assignedToEmail ?? 'zzzzzz'))
        .map((s, sI) => ({ ...s, seat: sI + 1 }))
    );
  }, [dashboardResponse]);

  useEffect(() => {
    if (!publicPartnerUsersResponse?.publicPartner?.users) return;
    const users: PpPublicPartnerUserFragment[] = publicPartnerUsersResponse?.publicPartner?.users;
    const assignedEmails = seats.filter((e) => (e.assignedToEmail ?? '').length > 0).map((e) => e.assignedToEmail);
    const availableUsers = users.filter((e) => {
      const canReadData = e.canReadData;
      const isAssigned = assignedEmails.some((m) => m === e.email);
      if (canReadData === true && isAssigned === false) {
        return true;
      } else {
        return false;
      }
    });
    setAvailablePublicPartners(availableUsers);
  }, [publicPartnerUsersResponse, seats]);

  const onClickSave = () => {
    onClose(false);
  };

  const handleClose = () => {
    onClose(false);
  };

  const handleConfirmationCancel = () => {
    setOpenConfirmationModal(false);
  };

  const handleConfirmationOK = async (willAssignUserId?: string, willCancel?: boolean) => {
    setOpenConfirmationModal(false);

    if (selectedActionType === SeatActionType.Assign) {
      if (!willAssignUserId) return;

      let success = true;
      try {
        const result = await assignBrandDashboardSeatMutation({
          variables: {
            userId: willAssignUserId,
            brandId: brandId,
            dashboardName: dashboardName,
          },
        });
        if (result.data?.assignBrandDashboardSeatPublicUser !== true) {
          success = false;
        }
      } catch (e) {
        createFailureNotification('Failed to get seat details. Please contact support');
        success = false;
      }

      if (success === true) {
        refetchDashboardQuery();
        refetchPublicPartnerUsers();
        createSuccessNotification('Associated seat');
      }
    } else if (selectedActionType === SeatActionType.Unassign) {
      let success = true;
      try {
        const result = await unassignBrandDashboardSeatMutation({
          variables: {
            userId: selectedUserId,
            brandId: brandId,
            dashboardName: dashboardName,
          },
        });
        if (result.data?.unassignBrandDashboardSeatPublicUser !== true) {
          success = false;
        }
      } catch (e) {
        createFailureNotification('Failed to get seat details. Please contact support');
        success = false;
      }

      if (success === true) {
        refetchDashboardQuery();
        refetchPublicPartnerUsers();
        createSuccessNotification('Removed seat association');
      }
    } else if (selectedActionType === SeatActionType.Cancel && willCancel === true) {
      let success = true;
      try {
        const result = await cancelSeatEvalMutation({
          variables: {
            brandId: brandId,
            dashboardName: dashboardName,
            id: selectedSeatId,
            evalOnly: false,
          },
        });
        if (!result.data?.cancelBrandDashboardSeatPublicUser) {
          success = false;
        }
      } catch (e) {
        createFailureNotification('Failed to get seat details. Please contact support');
        success = false;
      }

      if (success) {
        refetchDashboardQuery();
        refetchPublicPartnerUsers();
        createSuccessNotification('Cancelled seat');
      }
    }
  };

  const Columns = useMemo(() => {
    const handleAssign = (index: number) => {
      const seat = seats[index];

      if (!seat.assignedToEmail) {
        setSelectedActionType(SeatActionType.Assign);
        setSelectedEmail('');
        setOpenConfirmationModal(true);
      } else if (!!seat.assignedToPublicUser && seat.assignedToPublicUser.length > 0) {
        setSelectedActionType(SeatActionType.Unassign);
        if (!!seat.assignedToPublicUser) {
          const users = seat.assignedToPublicUser.filter((e) => e.email === seat.assignedToEmail);
          if (users.length > 0) {
            if (users.length > 1) {
              console.log('Multiple users with the same email', users.map((e) => e.id));
            }
            setSelectedUserId(users[0].id);
          }
        }
        setSelectedEmail(seat.assignedToEmail);
        setOpenConfirmationModal(true);
      }
    };

    const handleCancel = (index: number) => {
      const seat = seats[index];
      setSelectedSeatId(seat.id);
      setSelectedEmail('');
      setSelectedActionType(SeatActionType.Cancel);
      setOpenConfirmationModal(true);
    };

    const handleCancelled = (index: number) => {
      const seat = seats[index];
      setSelectedSeatId(seat.id);
      setSelectedEmail('');
      setSelectedActionType(SeatActionType.Cancelled);
      setOpenConfirmationModal(true);
    };

    return [
      {
        label: 'Seat #',
        name: 'seat',
        options: {
          filter: false,
          empty: true,
          customBodyRenderLite: (dataIndex: number) => {
            return <Typography>{dataIndex + 1}</Typography>;
          },
        },
      },
      {
        label: 'Assigned to',
        name: 'assignedToEmail',
      },
      {
        label: 'Status',
        name: 'status',
        options: {
          setCellProps: () => ({ style: { maxWidth: '110px' } }),
          customBodyRenderLite: (dataIndex: number) => {
            const status = (seats[dataIndex] as unknown as { status: string }).status;
            const isUpcomingCancellation = seats[dataIndex].isSoftCancelled;

            const lines = status.split('\n');
            return (
              <>
                {lines.map((l, lI) => (
                  <div key={`status_${lI}`}>
                    {lI ? <br style={{ content: '" "' }} /> : null}
                    {isUpcomingCancellation ? (
                      <Typography
                        style={{
                          color: '#3f51b5',
                          fontSize: '1em',
                          fontWeight: 700,
                          cursor: 'pointer',
                        }}
                        onClick={() => handleCancelled(dataIndex)}
                      >
                        {l}
                      </Typography>
                    ) : (
                      <Typography style={{ fontSize: '1em' }}>{l}</Typography>
                    )}
                  </div>
                ))}
              </>
            );
          },
        },
      },
      {
        label: 'Actions',
        name: 'actions',
        options: {
          filter: false,
          sort: false,
          empty: true,
          customBodyRenderLite: (dataIndex: number) => {
            const seat = seats[dataIndex];
            const isAssigned = seat.assignedToEmail !== null;
            const isCancellable = seat.isFreeSeat === false && seat.isSoftCancelled === false;

            const isUnassignable = isAssigned && (seat?.assignedToPublicUser?.length ?? 0) > 0 && seat?.assignedToEmail;
            const showButton = (isAssigned && isUnassignable) || !isAssigned;

            return (
              <StyledActionsContainer>
                {showButton && <Button
                  style={{ paddingLeft: 0, textTransform: 'none', fontSize: 14 }}
                  onClick={() => handleAssign(dataIndex)}
                >
                  {isAssigned ? 'Unassign' : 'Assign'}
                </Button>}
                {isCancellable && (
                  <Button
                    style={{ paddingLeft: 0, textTransform: 'none', fontSize: 14 }}
                    onClick={() => handleCancel(dataIndex)}
                  >
                    Cancel
                  </Button>
                )}
              </StyledActionsContainer>
            );
          },
        },
      },
    ];
  }, [seats]);

  const options: MUIDataTableOptions = {
    print: false,
    download: false,
    search: false,
    filter: false,
    viewColumns: false,
    selectableRowsHideCheckboxes: true,
    pagination: false,
    sortOrder: {
      direction: 'asc',
      name: 'seat',
    },
    tableBodyMaxHeight: '666px',
  };

  const getChildren = () => {
    return (
      <StyledContainer>
        <CircularSpinner shown={loadingSeats || loadingUsers || assigning || unassigning || loadingCancel} />
        <MUIDataTable title="" columns={Columns} data={seats} options={options} />
        <StyledOkButtonContainer>
          <Button style={{ width: 160, height: 40 }} variant={'contained'} onClick={onClickSave}>
            OK
          </Button>
        </StyledOkButtonContainer>
        <SeatActionConfirmationModal
          open={openConfirmationModal}
          type={selectedActionType}
          brandId={brandId}
          seatId={selectedSeatId}
          brandName={brandName}
          dashboardName={dashboardName}
          email={selectedEmail}
          partners={availablePublicPartners}
          onOK={(id, cancel) => handleConfirmationOK(id, cancel)}
          onClose={handleConfirmationCancel}
        />
      </StyledContainer>
    );
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ color: '#fff', marginBottom: 4 }}>
          {`Seats for ${dashboardName} [${brandName}]`}
        </Typography>
        {getChildren()}
      </Box>
    </Modal>
  );
};

const StyledContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 12px;
  gap: 24px;
`;

const StyledActionsContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
`;

const StyledOkButtonContainer = styled.div`
  display: flex;
  justify-content: right;
  margin-top: auto;
`;
